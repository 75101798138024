import api from "@/api";
import normalizaRota from "@/utils/normalizaRota";
import { setWithExpiry, getWithExpiry } from "../utils/storage";

class CategoriaRepository {
  async buscarTodas() {
    const jsonCategorias = getWithExpiry("categorias");

    if (jsonCategorias) {
      return JSON.parse(jsonCategorias);
    }

    const { data } = await api.get("/", {
      params: {
        route: "categories"
      }
    });

    let categorias = data.map(categoria => {
      categoria.link = normalizaRota(categoria.link);

      categoria.sub = categoria.sub.map(subcategoria => {
        subcategoria.link = normalizaRota(subcategoria.link);

        return subcategoria;
      });

      return categoria;
    });

    setWithExpiry("categorias", JSON.stringify(categorias), 120);

    return categorias;
  }
}

export default new CategoriaRepository();
